<template>
    <div class="component component-content6">
        <div class="icon">
            <div class="img-1"><img src="../../assets/images/pc/content6-1.png" /></div>
            <div class="img-2"><img src="../../assets/images/pc/content6-2.png" /></div>
        </div>
        <div class="img1"><img src="../../assets/images/common/icon/10.png" /></div>
        <div class="content">
            <div class="title" v-html="$t('content6_1')"></div>
            <div class="info">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="date">{{item.date}}</div>
                    <div class="line">
                        <div class="checkbox">
                            <img v-if="item.checked" src="../../assets/images/pc/content6-3.png" />
                        </div>
                    </div>
                    <div class="text">
                        {{item.text}}
                    </div>
                </div>
                <div class="time-line"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content6',
    data() {
        return {
            list: [
                {
                    date: this.$t('content6_2'),
                    checked: true,
                    text: this.$t('content6_3')
                },
                {
                    date: this.$t('content6_4'),
                    checked: true,
                    text: this.$t('content6_5')
                },
                {
                    date: this.$t('content6_6'),
                    checked: false,
                    text: this.$t('content6_7')
                },
                {
                    date: this.$t('content6_8'),
                    checked: false,
                    text: this.$t('content6_9')
                }
            ]
        }
    }
}
</script>
