<template>
    <div class="component component-content1">
        <div class="title">
            <img src="../../assets/images/pc/content-1.png" />
        </div>
        <div class="info">
            {{$t('content1_1')}}
        </div>
        <div class="button">
            <button><a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">{{$t('content1_2')}}</a></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content1'
}
</script>

<style lang="less">
.component-content1 {

}
</style>
