<template>
    <div class="component component-content5">
        <div class="icon">
            <div class="img-1"><img src="../../assets/images/pc/content5-1.png" /></div>
            <div class="img-2"><img src="../../assets/images/pc/content5-2.png" /></div>
        </div>
        <div class="img1"><img src="../../assets/images/common/icon/13.png" /></div>
        <div class="img2"><img src="../../assets/images/common/icon/14.png" /></div>
        <div class="img3"><img src="../../assets/images/common/icon/15.png" /></div>
        <div class="img4"><img src="../../assets/images/common/icon/16.png" /></div>
        <div class="img5"><img src="../../assets/images/common/icon/17.png" /></div>
        <div class="img6"><img src="../../assets/images/common/icon/18.png" /></div>
        <div class="content">
            {{$t('content5_1')}}
        </div>
        <div class="button">
            <button><a target="_blank" href="https://exchange.pancakeswap.finance#/swap?outputCurrency=0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">{{$t('content5_2')}}</a></button>
        </div>
        <div class="content">
            {{$t('content5_3')}}
        </div>
        <div class="tabs">
            <div class="tab">
                <span :class="{active: active === 0}" @click="active=0">STEP 1</span>
                <span :class="{active: active === 1}" @click="active=1">STEP 2</span>
                <span :class="{active: active === 2}" @click="active=2">STEP 3</span>
                <span :class="{active: active === 3}" @click="active=3">STEP 4</span>
                <div :class="`active${active}`"></div>
            </div>
            <div class="tab-content">
                <div class="step-list" :class="`active${active}`">
                    <div class="info tab-1">
                        <h2>{{$t('content5_4')}}</h2>
                        <div v-html="$t('content5_5')"></div>
                    </div>
                    <div class="info tab-2">
                        <h2>{{$t('content5_6')}}</h2>
                        <div v-html="$t('content5_7')"></div>
                        <label>{{$t('content5_8')}}</label>
                        <div class="tables">
                            <div>0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7</div>
                            <button @click="copy('#copy1')" id="copy1" data-clipboard-text="0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">{{$t('content5_9')}}</button>
                        </div>
                        <label>{{$t('content5_10')}}</label>
                        <div class="tables">
                            <div>BLOW UP COIN</div>
                            <button @click="copy('#copy2')" id="copy2" data-clipboard-text="BLOW UP COIN">{{$t('content5_9')}}</button>
                        </div>
                        <label>{{$t('content5_12')}}</label>
                        <div class="tables">
                            <div>BUP</div>
                            <button @click="copy('#copy3')" id="copy3" data-clipboard-text="BLOW UP">{{$t('content5_9')}}</button>
                        </div>
                        <label>{{$t('content5_13')}}</label>
                        <div class="tables">
                            <div>18</div>
                            <button @click="copy('#copy4')" id="copy4" data-clipboard-text="18">{{$t('content5_9')}}</button>
                        </div>
                    </div>
                    <div class="info tab-3">
                        <h2>{{$t('content5_14')}}</h2>
                        <div v-html="$t('content5_15')"></div>
                    </div>
                    <div class="info tab-4">
                        <h2>{{$t('content5_16')}}</h2>
                        <div v-html="$t('content5_17')"></div>
                        <div class="tables">
                            <div>0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7</div>
                            <button @click="copy('#copy5')" id="copy5" data-clipboard-text="0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">{{$t('content5_9')}}</button>
                        </div>
                        <br />
                        {{$t('content5_18')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'

const clipboardObject = {}
export default {
    name: 'content5',
    data() {
        return {
            active: 0
        }
    },
    methods: {
        copy(target) {
            if (!clipboardObject[target]) {
                clipboardObject[target] = new Clipboard(target)
                clipboardObject[target].on('success', () => {
                    this.$message({
                        type: 'success',
                        message: 'Copy succeeded'
                    })
                })
            }
        }
    }
}
</script>
