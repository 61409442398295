<template>
    <div class="component component-content3">
        <div class="img1"><img src="../../assets/images/common/icon/4.png" /> </div>
        <div class="img2"><img src="../../assets/images/common/icon/5.png" /> </div>
        <div class="img3"><img src="../../assets/images/common/icon/7.png" /> </div>
        <div class="img4"><img src="../../assets/images/common/icon/6.png" /> </div>
        <div class="icon">
            <div class="img-1"><img src="../../assets/images/pc/content3-1.png" /></div>
            <div class="img-2"><img src="../../assets/images/pc/content3-2.png" /></div>
        </div>
        <div class="content" v-html="$t('content3_1')" />
        <div class="button">
            <button><a target="_blank" :href="cpd_wp">{{$t('content3_2')}}</a></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content3',
    computed: {
        cpd_wp() {
            return `http://www.blowupcoin.com/files/white_paper_eng.pdf?${new Date().getTime()}`
        }
    }
}
</script>
