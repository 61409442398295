<template>
    <div class="pc-home">
        <div class="baomihua"></div>
        <div class="pc-container">
            <div class="container-bg">
                <div class="bg-warp">
                    <div v-for="(item, index) in 100" :class="{o: index % 2 === 0}" :key="item"></div>
                </div>
            </div>
            <div class="pc-content">
                <content1 />
                <content2 />
                <a id="bolwup"></a>
                <content3 />
                <a id="tokenomics"></a>
                <content4 />
                <a id="buy"></a>
                <content5 />
                <a id="roadmap"></a>
                <content6 />
            </div>
        </div>
    </div>
</template>

<script>
import content1 from "../../components/common/content1"
import content2 from "../../components/common/content2"
import content3 from "../../components/common/content3"
import content4 from "../../components/common/content4"
import content5 from "../../components/common/content5"
import content6 from "../../components/common/content6"
export default {
    name: 'PcHome',
    components: {
        content1,
        content2,
        content3,
        content4,
        content5,
        content6
    }
}
</script>

<style lang="less">
.pc-home {
    width: 100%;
    padding-top: 70px;
    .baomihua {
        width: 100%;
        height: 268px;
        background-image: url("../../assets/images/pc/header.png");
        background-position: center center;
        background-size: auto 150%;
    }
    .pc-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        min-height: 800px;
        top: -18px;
        padding-top: 48px;
        padding-bottom: 48px;
        .container-bg {
            position: absolute;
            width: 100%;
            height: calc(100% + 18px);
            top: 0;
            overflow: hidden;
            .bg-warp {
                width: 500%;
                height: 100%;
                display: flex;
                div {
                    width: 48px;
                    border-radius: 24px 24px 0 0;
                    background-color: #ffffff;
                    &.o {
                        background-color: #D0423F;
                    }
                }
            }
        }
        .pc-content {
            position: relative;
            width: 1200px;
            margin: 0 auto;
            .component-content1 {
                position: relative;
                width: 780px;
                background-color: #fff;
                margin: 0 auto;
                padding: 60px 94px 36px 94px;
                border: solid 8px #000;
                border-radius: 24px;
                .title {
                    position: absolute;
                    width: 238px;
                    top: -152px;
                    left: 50%;
                    margin-left: -104px;
                    img {
                        width: 100%;
                    }
                }
                .info {
                    /*text-align: justify;*/
                    font-size: 28px;
                }
                .button {
                    display: flex;
                    justify-content: center;
                    padding-top: 16px;
                    button {
                        background-color: #FFD917;
                        padding: 6px 24px;
                        font-weight: bold;
                        border: solid 3px #000;
                        border-radius: 11px;
                        font-family: Hamish;
                        font-size: 28px;
                    }
                }
            }
            .component-content2 {
                width: 780px;
                margin: 24px auto;
                background: #ffffff;
                padding: 12px;
                border: solid 2px #DADADA;
                .content2-content {
                    border: solid 5px #000;
                    padding: 24px 36px;
                    position: relative;
                    .img1 {
                        position: absolute;
                        right: -110px;
                        top: -20px;
                        img {
                            width: 120px;
                        }
                    }
                    .img2 {
                        position: absolute;
                        left: -310px;
                        top: 320px;
                        img {
                            width: 80px;
                        }
                    }
                    .title {
                        font-size: 36px;
                    }
                    .price {
                        font-size: 46px;
                        font-weight: bold;
                        font-family: Hamish;
                        padding: 4px 0;
                    }
                    .form {
                        border: solid #000;
                        border-width: 16px 0 15px 0;
                        .item {
                            border-bottom: solid 2px #000;
                            display: flex;
                            justify-content: space-between;
                            padding: 16px 0 6px 0;
                            label {
                                font-size: 28px;
                            }
                            .value {
                                font-family: Hamish;
                                font-size: 32px;
                            }
                        }
                    }
                    .button {
                        display: flex;
                        justify-content: center;
                        padding-top: 32px;
                        button {
                            background: #000;
                            padding: 8px 24px;
                            border-radius: 5px;
                            color: #fff;
                            font-size: 36px;
                            a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .component-content3 {
                width: 780px;
                margin: 96px auto 0 auto;
                position: relative;
                background: #fff;
                min-height: 200px;
                padding: 82px 84px 36px 84px;
                border: solid 8px #000;
                border-radius: 24px;
                position: relative;
                .img1 {
                    position: absolute;
                    right: -190px;
                    top: 60px;
                    img {
                        width: 120px;
                    }
                }
                .img2 {
                    position: absolute;
                    right: -10px;
                    top: 460px;
                    img {
                        width: 80px;
                    }
                }
                .img3 {
                    position: absolute;
                    right: 180px;
                    bottom: -60px;
                    img {
                        width: 80px;
                    }
                }
                .img4 {
                    position: absolute;
                    left: -220px;
                    bottom: -10px;
                    img {
                        width: 90px;
                    }
                }
                .icon {
                    position: absolute;
                    top: 0;
                    left: -15px;

                    .img-1 {
                        position: relative;
                        z-index: 0;

                        img {
                            width: 136px;
                        }
                    }
                    .img-2 {
                        position: absolute;
                        z-index: 1;
                        top: -86px;
                        left: 50px;
                        img {
                            width: 366px;
                        }
                    }
                }
                .content {
                    font-size: 28px;
                    /*text-align: justify;*/
                }
                .button {
                    display: flex;
                    justify-content: center;
                    padding-top: 24px;
                    button {
                        background-color: #FFD917;
                        padding: 8px 24px;
                        font-weight: bold;
                        border: solid 3px #000;
                        border-radius: 11px;
                        font-family: Hamish;
                        font-size: 32px;
                    }
                }
            }
            .component-content4 {
                width: 780px;
                margin: 156px auto 0 auto;
                position: relative;
                background: #fff;
                min-height: 200px;
                padding: 66px 84px 36px 84px;
                border: solid 8px #000;
                border-radius: 24px;
                .img1 {
                    position: absolute;
                    left: -320px;
                    top: 1000px;
                    img {
                        width: 100px;
                    }
                }
                .img2 {
                    position: absolute;
                    left: -200px;
                    top: 1100px;
                    img {
                        width: 80px;
                    }
                }
                .img3 {
                    position: absolute;
                    right: -190px;
                    top: 1300px;
                    img {
                        width: 110px;
                    }
                }
                .img4 {
                    position: absolute;
                    left: -4px;
                    bottom: -54px;
                    img {
                        width: 110px;
                    }
                }
                .img5 {
                    position: absolute;
                    left: -294px;
                    bottom: -194px;
                    img {
                        width: 110px;
                    }
                }
                .icon {
                    position: absolute;
                    top: -78px;
                    left: 74px;

                    .img-1 {
                        position: relative;
                        z-index: 0;

                        img {
                            width: 164px;
                        }
                    }
                    .img-2 {
                        position: absolute;
                        z-index: 1;
                        top: -114px;
                        left: -146px;
                        img {
                            width: 230px;
                        }
                    }
                    .img-3 {
                        position: absolute;
                        z-index: 1;
                        top: -28px;
                        left: 128px;
                        img {
                            width: 282px;
                        }
                    }
                }
                .content {
                    font-size: 28px;
                    /*text-align: justify;*/
                    padding: 24px 36px 0 36px;
                    position: relative;
                    h2 {
                        color: #D0423F;
                        font-size: 42px;
                        margin-bottom: 24px;
                    }
                    .icon {
                        position: absolute;
                        top: 84px;
                        left: -116px;
                        img {
                            width: 150px;
                        }
                    }
                }
                .address {
                    width: 780px;
                    margin-left: -90px;
                    padding: 24px 98px;
                    background-color: rgba(0, 0, 0, 0.05);
                    position: relative;
                    h2 {
                        color: #D0423F;
                        font-size: 42px;
                        margin-bottom: 24px;
                        text-align: center;
                        padding-right: 54px;
                    }
                    .number {
                        position: relative;
                        border: solid 3px #000;
                        font-size: 22px;
                        background-color: #FFD917;
                        padding: 12px 0;
                        border-radius: 11px;
                        text-align: center;
                        z-index: 1;
                    }
                    .img {
                        position: absolute;
                        right: 0;
                        top: 8px;
                        z-index: 0;
                        img {
                            width: 200px;
                        }
                    }
                    .bottom {
                        height: 26px;
                        display: flex;
                        justify-content: space-between;
                        span {
                            width: 12px;
                            background-color: #000;
                            border-radius: 0 0 3px 3px;
                            &:first-of-type {
                                margin-left: 148px;
                            }
                            &:last-of-type {
                                margin-right: 148px;
                            }
                        }
                    }
                }
                .button {
                    display: flex;
                    justify-content: center;
                    padding-top: 24px;
                    button {
                        background-color: #FFD917;
                        padding: 6px 24px;
                        font-weight: bold;
                        border: solid 3px #000;
                        border-radius: 11px;
                        font-family: Hamish;
                        font-size: 18px;
                    }
                }
            }
            .component-content5 {
                width: 780px;
                margin: 174px auto 0 auto;
                position: relative;
                background: #fff;
                min-height: 200px;
                padding: 82px 64px 36px 64px;
                border: solid 8px #000;
                border-radius: 24px;
                .img1 {
                    position: absolute;
                    right: -190px;
                    top: 200px;
                    img {
                        width: 80px;
                    }
                }
                .img2 {
                    position: absolute;
                    right: -290px;
                    top: 240px;
                    img {
                        width: 70px;
                    }
                }
                .img3 {
                    position: absolute;
                    right: -240px;
                    top: 580px;
                    img {
                        width: 100px;
                    }
                }
                .img4 {
                    position: absolute;
                    left: -240px;
                    bottom: 120px;
                    img {
                        width: 100px;
                    }
                }
                .img5 {
                    position: absolute;
                    left: -70px;
                    bottom: 40px;
                    img {
                        width: 100px;
                    }
                }
                .img6 {
                    position: absolute;
                    right: 90px;
                    bottom: -40px;
                    img {
                        width: 100px;
                    }
                }
                .icon {
                    position: absolute;
                    top: -42px;
                    left: 380px;

                    .img-1 {
                        position: relative;
                        z-index: 0;

                        img {
                            width: 136px;
                        }
                    }
                    .img-2 {
                        position: absolute;
                        z-index: 1;
                        top: -154px;
                        left: -278px;
                        img {
                            width: 344px;
                        }
                    }
                }
                .content {
                    font-size: 28px;
                    /*text-align: justify;*/
                }
                .button {
                    display: flex;
                    justify-content: center;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    button {
                        background-color: #FFD917;
                        padding: 8px 24px;
                        font-weight: bold;
                        border: solid 3px #000;
                        border-radius: 11px;
                        font-size: 32px;
                    }
                }
                .tabs {
                    border: solid 5px #000;
                    border-radius: 9px;
                    margin-top: 36px;
                    .tab {
                        display: flex;
                        justify-content: center;
                        padding-top: 16px;
                        position: relative;
                        span {
                            flex: 1;
                            margin: 0 12px;
                            text-align: center;
                            font-size: 30px;
                            position: relative;
                            z-index: 5;
                            cursor: pointer;
                        }
                        div {
                            width: 114px;
                            position: absolute;
                            background-color: #D0423F;
                            height: 24px;
                            border-radius: 12px;
                            top: 34px;
                            left: 21px;
                            z-index: 1;
                            transition: transform 0.5s;
                            transform: translateX(0);
                            &.active0 {
                                transform: translateX(0);
                            }
                            &.active1 {
                                transform: translateX(159px);
                            }
                            &.active2 {
                                transform: translateX(315px);
                            }
                            &.active3 {
                                transform: translateX(474px);
                            }
                        }
                    }
                    .tab-content {
                        width: 100%;
                        overflow: hidden;
                        .step-list {
                            width: 400%;
                            display: flex;
                            min-height: 100px;
                            transition: transform 0.5s;
                            &.active0 {
                                transform: translateX(0);
                            }
                            &.active1 {
                                transform: translateX(-630px);
                            }
                            &.active2 {
                                transform: translateX(-1260px);
                            }
                            &.active3 {
                                transform: translateX(-1890px);
                            }
                            .info {
                                flex: 1;
                                padding: 36px 24px;
                                font-size: 28px;
                                /*text-align: justify;*/
                                h2 {
                                    text-align: center;
                                    color: #D0423F;
                                    border: solid 3px #D0423F;
                                    padding: 6px 0;
                                    border-radius: 9px;
                                    font-size: 26px;
                                    width: 90%;
                                    margin: 0 auto 36px auto;
                                }
                                a {
                                    color: #3550D7;
                                }
                                label {
                                    color: #4F4F4F;
                                }
                                .tables {
                                    width: 100%;
                                    display: flex;
                                    background-color: rgba(0, 0, 0, 0.05);
                                    div {
                                        flex: 1;
                                        word-break: break-all;
                                        font-size: 24px;
                                        line-height: 22px;
                                        padding-right: 12px;
                                    }
                                    button {
                                        width: 140px;
                                        background-color: #FFD917;
                                        border: solid 3px #000;
                                        border-radius: 9px;
                                        font-size: 24px;
                                        line-height: 22px;
                                        height: 44px;
                                        overflow: hidden;
                                        padding-bottom: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .component-content6 {
                width: 780px;
                margin: 96px auto 0 auto;
                position: relative;
                background: #fff;
                min-height: 200px;
                padding: 10px;
                border: solid 5px #000;
                .img1 {
                    position: absolute;
                    top: 486px;
                    right: 68px;
                    img {
                        width: 80px;
                    }
                }
                .icon {
                    position: absolute;
                    top: 34px;
                    left: 62px;

                    .img-1 {
                        position: relative;
                        z-index: 0;

                        img {
                            width: 116px;
                        }
                    }
                    .img-2 {
                        position: absolute;
                        z-index: 1;
                        top: -96px;
                        left: 56px;
                        img {
                            width: 320px;
                        }
                    }
                }
                .content {
                    font-size: 28px;
                    /*text-align: justify;*/
                    border: solid 5px #000;
                    .title {
                        background-color: #D0423F;
                        margin: 36px 0;
                        padding: 24px 36px 24px 286px;
                        color: #fff;
                    }
                    .line {
                        padding-top: 8px;
                        .checkbox {
                            width: 40px;
                            height: 40px;
                            border: solid 8px #000;
                            background-color: #fff;
                            position: relative;
                            img {
                                width: 46px;
                                position: absolute;
                                left: -5px;
                                top: -11px;
                            }
                        }
                    }
                    .info {
                        padding: 0 36px;
                        position: relative;
                        .time-line {
                            position: absolute;
                            width: 12px;
                            background-color: #000;
                            height: 100%;
                            top: 15px;
                            left: 282px;
                            z-index: 0;
                        }
                        .item {
                            display: flex;
                            padding-bottom: 36px;
                            position: relative;
                            z-index: 1;
                            .date {
                                width: 232px;
                                text-align: right;
                                padding-right: 24px;
                            }
                            .line {
                                width: 80px;
                            }
                            .text {
                                flex: 1;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
