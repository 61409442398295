<template>
    <div class="component component-content2">
        <div class="content2-content">
            <div class="img1"><img src="../../assets/images/common/icon/11.png" /> </div>
            <div class="img2"><img src="../../assets/images/common/icon/9.png" /> </div>
            <div class="title">{{$t('content2_1')}}</div>
            <div class="price">${{form.price}}</div>
            <div class="form">
                <div class="item">
                    <label>{{$t('content2_2')}}</label>
                    <div class="value">${{form.liquidity}}</div>
                </div>
                <div class="item">
                    <label>{{$t('content2_3')}}</label>
                    <div class="value">{{form.holders}}</div>
                </div>
                <div class="item">
                    <label>{{$t('content2_4')}}</label>
                    <div class="value">{{form.supply}}</div>
                </div>
                <div class="item">
                    <label>{{$t('content2_5')}}</label>
                    <div class="value">${{form.marketCap}}</div>
                </div>
            </div>
            <div class="button">
                <button><a target="_blank" href="https://poocoin.app/tokens/0x8b4F96981e79d633A796Dc06672A9928CCE5aBA7">{{$t('content2_6')}}</a></button>
            </div>
        </div>
    </div>
</template>

<script>

function convert(n) {
    n += ''
    const x = n.split('.')
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : ''
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return x1 + x2
}

export default {
    name: 'content2',
    data() {
        return {
            form: {
                price: '',
                liquidity: '',
                holders: '',
                supply: '',
                marketCap: ''
            },
            timeout: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.$ajax('market', this.$api.market()).then(res => {
                if (res.code === 0) {
                    this.form = {
                        price: convert(res.data.price),
                        liquidity: convert(res.data.liquidity),
                        holders: convert(res.data.holders),
                        supply: convert(res.data.supply),
                        marketCap: convert(res.data.marketCap)
                    }
                }
                this.timeout = setTimeout(() => {
                    this.getData()
                }, 1000 * 60)
            })
        }
    }
}
</script>
