<template>
    <div class="component component-content4">
        <div class="icon">
            <div class="img-1"><img src="../../assets/images/pc/content4-1.png" /></div>
            <div class="img-2"><img src="../../assets/images/pc/content4-2.png" /></div>
            <div class="img-3"><img src="../../assets/images/pc/content4-3.png" /></div>
        </div>
        <div class="img1"><img src="../../assets/images/common/icon/8.png" /></div>
        <div class="img2"><img src="../../assets/images/common/icon/9.png" /></div>
        <div class="img3"><img src="../../assets/images/common/icon/10.png" /></div>
        <div class="img4"><img src="../../assets/images/common/icon/11.png" /></div>
        <div class="img5"><img src="../../assets/images/common/icon/12.png" /></div>
        <div class="content">
            <div class="icon">
                <img src="../../assets/images/pc/content4-4.png" />
            </div>
            <h2>{{$t('content4_1')}} </h2>
            <div v-html="$t('content4_2')"></div>
        </div>
        <div class="address">
            <h2>{{$t('content4_3')}}</h2>
            <div class="img">
                <img src="../../assets/images/pc/content4-5.png" />
            </div>
            <div class="number">
                <a target="_blank" href="https://bscscan.com/address/0xb75ad2a7d66f6df1cfd8d1041df7a1d824bb8297">0xb75ad2a7d66f....f7a1d824bb8297</a>
            </div>
            <div class="bottom">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="content">
            <div class="icon">
                <img src="../../assets/images/pc/content4-6.png" />
            </div>
            <h2>{{$t('content4_4')}}</h2>
            <div v-html="$t('content4_5')"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content4'
}
</script>
